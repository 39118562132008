import { graphql, PageProps } from "gatsby"
import React from "react"
import NieuwsComponent from "../components/page/nieuws"
import { TemplateNieuwsQuery } from "../__generated__/graphql-gatsby"

type IProps = PageProps<TemplateNieuwsQuery>

const NieuwsPage: React.FC<IProps> = ({
  data: { wpNieuws, next, previous },
}) => {
  const key = String(wpNieuws?.uri)

  return (
    <NieuwsComponent
      data={wpNieuws}
      next={next}
      previous={previous}
      sessionKey={key}
    />
  )
}

export default NieuwsPage

export const query = graphql`
  fragment PageBuilderNieuwsImage on WpNieuws_PageBuilder_PageBuilder_Afbeelding {
    __typename
    maxWidth
    afbeelding {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  fragment PageBuilderNieuwsCTA on WpNieuws_PageBuilder_PageBuilder_Cta {
    __typename
    titel
    subtitel
    uitlijningTitel
    linkKnop {
      title
      url
      target
    }
    uitlijningKnop
  }
  fragment PageBuilderNieuwsText on WpNieuws_PageBuilder_PageBuilder_TekstEditor {
    __typename
    instellingen {
      achtergrond
      breedte
    }
    tekst
  }
  fragment PageBuilderNieuwsSpacer on WpNieuws_PageBuilder_PageBuilder_Spacer {
    __typename
    hoogte
  }
  fragment PageBuilderNieuwsFaq on WpNieuws_PageBuilder_PageBuilder_Faq {
    __typename
    titel
    tekst
    kleur
    standaardGesloten
    metaData
  }
  fragment PageBuilderNieuwsUitgelichteActiviteit on WpNieuws_PageBuilder_PageBuilder_UitgelichteActiviteit {
    __typename
    flip
    onlyImg
    activiteit {
      ...UitgelichteActiviteit
    }
  }
  fragment PageBuilderNieuwsUitgelichteErvaring on WpNieuws_PageBuilder_PageBuilder_UitgelichteErvaring {
    __typename
    flip
    onlyImg
    ervaring {
      ...UitgelichteErvaring
    }
  }
  fragment PageBuilderNieuwsLaatsteBerichten on WpNieuws_PageBuilder_PageBuilder_LaatsteBerichten {
    __typename
    type {
      nodesOfPostTypes {
        nodes(limit: 5) {
          ...LaatsteBerichtenData
        }
      }
    }
    aantal
  }
  fragment PageBuilderNieuwsAgenda on WpNieuws_PageBuilder_PageBuilder_Agenda {
    __typename
    weergave
    tags {
      id
    }
  }
  fragment PageBuilderNieuwsForm on WpNieuws_PageBuilder_PageBuilder_Formulier {
    __typename
    nieuwsbriefFormulier
    addtext
    text
    redirect {
      url
    }
    form {
      ...Form
    }
  }
  fragment PageBuilderNieuwsOverzicht on WpNieuws_PageBuilder_PageBuilder_OverzichtPaginas {
    __typename
    format
    sortering
    tags {
      id
    }
  }
  fragment PageBuilderNieuwsYoutube on WpNieuws_PageBuilder_PageBuilder_Youtube {
    __typename
    titel
    toonTitel
    youtubeId
    aspectRatio
    tekst
    metTekst {
      plaatsTekst
      achtergrond
      breedteContainer
    }
  }
  fragment PageBuilderNieuwsFacebook on WpNieuws_PageBuilder_PageBuilder_Facebook {
    __typename
    tabs
  }
  fragment PageBuilderNieuwsButtonsGroup on WpNieuws_PageBuilder_PageBuilder_ButtonsGroup {
    __typename
    buttons {
      button {
        url
        title
        target
      }
    }
  }
  fragment PageBuilderNieuwsBlocks on WpNieuws_PageBuilder_PageBuilder {
    ... on WpNieuws_PageBuilder_PageBuilder_TekstEditor {
      ...PageBuilderNieuwsText
    }
    ... on WpNieuws_PageBuilder_PageBuilder_Spacer {
      ...PageBuilderNieuwsSpacer
    }
    ... on WpNieuws_PageBuilder_PageBuilder_Afbeelding {
      ...PageBuilderNieuwsImage
    }
    ... on WpNieuws_PageBuilder_PageBuilder_Faq {
      ...PageBuilderNieuwsFaq
    }
    ... on WpNieuws_PageBuilder_PageBuilder_Cta {
      ...PageBuilderNieuwsCTA
    }
    ... on WpNieuws_PageBuilder_PageBuilder_UitgelichteActiviteit {
      ...PageBuilderNieuwsUitgelichteActiviteit
    }
    ... on WpNieuws_PageBuilder_PageBuilder_UitgelichteErvaring {
      ...PageBuilderNieuwsUitgelichteErvaring
    }
    ... on WpNieuws_PageBuilder_PageBuilder_LaatsteBerichten {
      ...PageBuilderNieuwsLaatsteBerichten
    }
    ... on WpNieuws_PageBuilder_PageBuilder_OverzichtPaginas {
      ...PageBuilderNieuwsOverzicht
    }
    ... on WpNieuws_PageBuilder_PageBuilder_Agenda {
      ...PageBuilderNieuwsAgenda
    }
    ... on WpNieuws_PageBuilder_PageBuilder_Formulier {
      ...PageBuilderNieuwsForm
    }
    ... on WpNieuws_PageBuilder_PageBuilder_Youtube {
      ...PageBuilderNieuwsYoutube
    }
    ... on WpNieuws_PageBuilder_PageBuilder_Facebook {
      ...PageBuilderNieuwsFacebook
    }
    ... on WpNieuws_PageBuilder_PageBuilder_ButtonsGroup {
      ...PageBuilderNieuwsButtonsGroup
    }
  }
  fragment PageBuilderNieuws on WpNieuws_PageBuilder {
    pageBuilder {
      ...PageBuilderNieuwsBlocks
    }
  }
  fragment wpNieuws on WpNieuws {
    id
    title
    uri
    seo {
      ...SEO
    }
    page_builder {
      ...PageBuilderNieuws
    }
  }
  fragment NieuwsEdge on WpNieuws {
    id
    title
    uri
  }

  query TemplateNieuws($id: String!, $next: String, $previous: String) {
    wpNieuws(id: { eq: $id }) {
      ...wpNieuws
    }
    next: wpNieuws(id: { eq: $next }) {
      ...NieuwsEdge
    }
    previous: wpNieuws(id: { eq: $previous }) {
      ...NieuwsEdge
    }
  }
`
